import * as React from "react";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import PlainHeader from "components/ui/base/PlainHeader";
import { useTranslation } from "react-i18next";
import ImageWithDescription from "components/ui/base/ImageWithDescription";
import NumberSteps from "components/ui/base/NumberSteps";
import FullWidthImage from "components/ui/base/FullWidthImage";
import ProductHunt from "components/ui/base/ProductHunt";
import HorizontalStoriesMenu from "components/ui/base/HorizontalStoriesMenu";
import FeatureGrid from "components/ui/base/FeatureGrid";
import TestimonialWithImageIrene from "components/ui/extended/TestimonialWithImageIrene";
import RegisterForCustomerService from "components/ui/extended/RegisterForCustomerService";
import Customers from "components/ui/extended/Customers";
import Savings from "components/ui/extended/HelpCenterSavings";
import { REVIEWS_STRUCTURED_DATA } from "constants/seo";
import { IGatsbyImageData } from "gatsby-plugin-image";
import { graphql } from "gatsby";

interface HelpCenterPageProps {
  data: {
    site: {
      siteMetadata: {
        title: string;
        siteUrl: string;
      };
    };
    metaImage: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
  };
  location: Location;
}

export default ({ data, location }: HelpCenterPageProps) => {
  const { t } = useTranslation();

  return (
    <GlobalWrapper withLayout location={location}>
      <SEO
        title={t("pages.features.help-center.seo.title")}
        description={t("pages.features.help-center.seo.description")}
        image={{
          relativePath: "meta/help-desk-software.jpg",
          alt: "Help Desk Software with Help Center, Live Chat",
        }}
        structuredData={[
          {
            "@context": "https://schema.org",
            "@type": "Product",
            name: t("pages.features.help-center.seo.title"),
            image: [
              data.site.siteMetadata.siteUrl +
                data.metaImage.childImageSharp.gatsbyImageData.images.fallback!
                  .src,
            ],
            description: t("pages.features.help-center.seo.description"),
            brand: {
              "@type": "Brand",
              name: "Customerly",
            },
            review: REVIEWS_STRUCTURED_DATA,
            aggregateRating: {
              "@type": "AggregateRating",
              ratingValue: 4.9,
              reviewCount: 389,
            },
          },
          {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            mainEntity: [1, 2, 3].map((index: number) => {
              return {
                "@type": "Question",
                name: t(`pages.features.help-center.faq.${index}.question`),
                acceptedAnswer: {
                  "@type": "Answer",
                  text: t(`pages.features.help-center.faq.${index}.answer`),
                },
              };
            }),
          },
        ]}
      />

      <PlainHeader
        title={t("pages.features.help-center.title")}
        titleMaxWidth={900}
        description={t("pages.features.help-center.description")}
        descriptionMaxWidth={700}
        ctaRegister={{ show: true }}
        image={{
          relativePath:
            "pages/features/help-center/help-center-software-with-live-chat.jpg",
          alt: "Help Center",
        }}
        breadcrumbs={[
          {
            name: "Customerly",
            path: "/",
          },
          {
            name: "Features",
            path: "/features/",
          },
          {
            name: "Help center",
            path: "/features/help-center/",
          },
        ]}
      />

      <Savings />

      <ImageWithDescription
        title={t("pages.features.help-center.average-cost.title")}
        image={{
          relativePath:
            "pages/features/help-center/help-center-ticket-save-costs-customer-service.jpg",
          alt: t("pages.features.help-center.average-cost.title"),
          position: "right",
          fullWidth: true,
        }}
        description={t("pages.features.help-center.average-cost.description")}
      />

      <NumberSteps
        title={t("pages.features.help-center.steps.title")}
        elements={Array.from(Array(4).keys()).map((i) => {
          return {
            title: t(`pages.features.help-center.steps.${i + 1}.title`),
            description: t(
              `pages.features.help-center.steps.${i + 1}.description`
            ),
          };
        })}
      />

      <FullWidthImage
        title={t("pages.features.help-center.create-articles.title")}
        description={t(
          "pages.features.help-center.create-articles.description"
        )}
        image={{
          relativePath:
            "pages/features/help-center/help-center-software-editor.jpg",
          alt: t("pages.features.help-center.create-articles.title"),
        }}
      />

      <FullWidthImage
        id={"article-editor"}
        title={t("pages.features.help-center.organize-articles.title")}
        description={t(
          "pages.features.help-center.organize-articles.description"
        )}
        image={{
          relativePath:
            "pages/features/help-center/help-center-software-organize-articles.jpg",
          alt: t("pages.features.help-center.organize-articles.title"),
        }}
      />

      <ImageWithDescription
        id={"chat-auto-reply"}
        title={t("pages.features.help-center.autoreply.title")}
        image={{
          relativePath:
            "pages/features/help-center/self-serve-help-center-software.jpg",
          alt: t("pages.features.help-center.autoreply.title"),
          position: "right",
          fullWidth: true,
        }}
        description={t("pages.features.help-center.autoreply.description")}
      />

      <ImageWithDescription
        title={t("pages.features.help-center.create-website.title")}
        image={{
          relativePath:
            "pages/features/help-center/create-help-center-website.jpg",
          alt: t("pages.features.help-center.create-website.title"),
          position: "left",
          fullWidth: true,
        }}
        description={t("pages.features.help-center.create-website.description")}
      />

      <ProductHunt
        url={
          "https://www.producthunt.com/posts/customerly-knowledge-base-platform?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-customerly-knowledge-base-platform"
        }
      />

      <ImageWithDescription
        id={"inchat-search"}
        title={t("pages.features.help-center.integrated-help-center.title")}
        image={{
          relativePath:
            "pages/features/help-center/integrated-help-center-software.jpg",
          alt: t("pages.features.help-center.integrated-help-center.title"),
          position: "right",
          fullWidth: true,
        }}
        description={t(
          "pages.features.help-center.integrated-help-center.description"
        )}
      />

      <ImageWithDescription
        id={"google-tag-manager"}
        title={t("pages.features.help-center.gtag.title")}
        image={{
          relativePath:
            "pages/features/help-center/add-google-tag-manager-to-help-center-software.jpg",
          alt: t("pages.features.help-center.gtag.title"),
          position: "left",
          fullWidth: true,
        }}
        description={t("pages.features.help-center.gtag.description")}
      />

      <HorizontalStoriesMenu
        id={"article-feedback"}
        title={t("pages.features.help-center.performance.title")}
        paragraph={t("pages.features.help-center.performance.paragraph")}
        cta={{
          text: t("pages.features.help-center.performance.discoverMore"),
          link: "/features/team-reports/#help-center-reports",
        }}
        storiesPosition={"right"}
        elements={[
          {
            title: t("pages.features.help-center.performance.customerFeedback"),
            story: {
              relativePath:
                "pages/features/help-center/help-center-feedback.mp4",
            },
          },
          {
            title: t(
              "pages.features.help-center.performance.customerFeedbackReport"
            ),
            story: {
              relativePath:
                "pages/features/help-center/help-center-feedback-report.mp4",
            },
          },
          {
            title: t(
              "pages.features.help-center.performance.customerFeedbackFailedSearches"
            ),
            story: {
              relativePath:
                "pages/features/help-center/help-center-failed-searches.mp4",
            },
          },
        ]}
      />

      <FeatureGrid
        centeredTitle
        title={t("pages.features.help-center.super-powers.title")}
        sections={[
          {
            title: t(
              "pages.features.help-center.super-powers.video-live-chat.title"
            ),
            description: t(
              "pages.features.help-center.super-powers.video-live-chat.description"
            ),
            image: {
              relativePath: "icons/video-live-chat.png",
              alt: t(
                "pages.features.help-center.super-powers.video-live-chat.title"
              ),
            },
            links: [
              {
                text: t(
                  "pages.features.help-center.super-powers.video-live-chat.cta"
                ),
                path: "/features/video-live-chat/",
              },
            ],
          },
          {
            title: t("pages.features.help-center.super-powers.surveys.title"),
            description: t(
              "pages.features.help-center.super-powers.surveys.description"
            ),
            image: {
              relativePath: "icons/surveys.png",
              alt: t("pages.features.help-center.super-powers.surveys.title"),
            },
            links: [
              {
                text: t("pages.features.help-center.super-powers.surveys.cta"),
                path: "/features/surveys/",
              },
            ],
          },
          {
            title: t(
              "pages.features.help-center.super-powers.automation.title"
            ),
            description: t(
              "pages.features.help-center.super-powers.automation.description"
            ),
            image: {
              relativePath: "icons/automation.png",
              alt: t(
                "pages.features.help-center.super-powers.automation.title"
              ),
            },
            links: [
              {
                text: t(
                  "pages.features.help-center.super-powers.video-live-chat.cta"
                ),
                path: "/solutions/marketing-automation/",
              },
            ],
          },
        ]}
      />

      <TestimonialWithImageIrene />

      <RegisterForCustomerService />

      <Customers />
    </GlobalWrapper>
  );
};
export const HelpCenterQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    metaImage: file(
      relativePath: { eq: "meta/help-desk-software.jpg" }
      sourceInstanceName: { eq: "images" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;
